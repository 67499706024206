export const state = () => ({
  user: {},
  authChangeState: {},
});

export const mutations = {
  SET_USER_DATA(state, data) {
    state.user = data;
  },
  SET_AUTH_CHANGE_STATE(state, data) {
    state.authChangeState = data;
  },
};

export const getters = {
  getAuthUser: (state) => () => {
    return state.user;
  },
  getAuthState: (state) => () => {
    return state.authChangeState;
  },
};
