var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"sidebar",staticClass:"vertical-menu",style:([
    _vm.$route.path.includes('care-plan') ||
    _vm.$route.path.includes('calendar') ||
    _vm.$route.name === 'users-id-tracks' ||
    _vm.$route.name == 'admin-index-tracks-id' ||
    (_vm.$route.name == 'admin-index-care_plans-planId' &&
      !_vm.$route.path.includes('/alert/'))
      ? { left: '0' }
      : _vm.$route.name == 'profilea'
      ? { width: '0' }
      : _vm.$route.name.includes('chats')
      ? { width: '20rem' }
      : {} ])},[_c('div',{staticClass:"navbar-brand-box",style:([
      _vm.$route.path.includes('profilea') && !_vm.$route.path.includes('chats')
        ? { 'box-shadow': '-2.5px 2px 4px #0f223a1f' }
        : {} ])},[_c('nuxt-link',{staticClass:"logo logo-dark text-left",attrs:{"to":_vm.logoRedirect}},[_c('span',{staticClass:"logo-sm"},[_c('img',{attrs:{"src":_vm.$vianovaConfig.options.logo_small,"alt":"","height":"22"}})]),_vm._v(" "),_c('span',{staticClass:"logo-lg"},[_c('img',{attrs:{"src":_vm.$vianovaConfig.options.logo,"alt":"","height":"30"}})])]),_vm._v(" "),_c('nuxt-link',{staticClass:"logo logo-light text-left",attrs:{"to":_vm.logoRedirect}},[_c('span',{staticClass:"logo-sm"},[_c('img',{attrs:{"src":_vm.$vianovaConfig.options.logo_small,"alt":"","height":"22"}})]),_vm._v(" "),_c('span',{staticClass:"logo-lg"},[_c('img',{attrs:{"src":_vm.$vianovaConfig.options.logo,"alt":"","height":"30"}})])])],1),_vm._v(" "),(
      !_vm.$route.name.includes('chats') &&
      _vm.$route.name !== 'users-id-tracks' &&
      _vm.$route.name != 'chats' &&
      !_vm.$route.name.includes('calendar') &&
      _vm.$route.name != 'admin-index-care_plans-planId'
    )?_c('button',{staticClass:"btn btn-sm px-3 font-size-16 header-item vertical-menu-btn",attrs:{"type":"button"},on:{"click":_vm.toggleMenu}},[_c('i',{staticClass:"fa fa-fw fa-bars"})]):_vm._e(),_vm._v(" "),_c('div',{ref:"sidebar",staticClass:"sidebar-menu-scroll",attrs:{"data-simplebar":""}},[_c('div',{attrs:{"id":"sidebar-menu"}},[_c('ul',{staticClass:"metismenu list-unstyled",attrs:{"id":"side-menu"}},[(
            _vm.$route.name === 'users-id-tracks' ||
            _vm.$route.name == 'admin-index-tracks-id' ||
            (_vm.$route.name == 'admin-index-care_plans-planId' &&
              !_vm.$route.fullPath.includes('/alert/'))
          )?[_c('li',{staticClass:"menu-title"}),_vm._v(" "),_c('li',[(
                _vm.$route.name == 'admin-index-care_plans-planId' &&
                !_vm.$route.fullPath.includes('care_plans/alert')
              )?_c('nuxt-link',{staticClass:"side-nav-link-ref",attrs:{"to":{ name: "admin-index-care_plans" }}},[_c('i',{staticClass:"uil-left-arrow-to-left"}),_vm._v(" "),_c('span',[_vm._v("Care Plans")])]):(_vm.$route.name == 'admin-index-tracks-id')?_c('nuxt-link',{staticClass:"side-nav-link-ref",attrs:{"to":{ name: "admin-index-tracks" }}},[_c('i',{staticClass:"uil-left-arrow-to-left"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("previous_step.tracks")))])]):_vm._e()],1),_vm._v(" "),_c('li',[(_vm.$route.name === 'users-id-tracks')?_c('nuxt-link',{staticClass:"side-nav-link-ref",attrs:{"to":{
                name:
                  _vm.$store.state.modules.dashboard.previousStep &&
                  [
                    'users-id-overview',
                    'users-id-overview-vitals',
                    'users-id-overview-lab-vitals',
                    'users-id-overview-medications' ].includes(_vm.$store.state.modules.dashboard.previousStep)
                    ? 'users-id-overview'
                    : _vm.$store.state.modules.dashboard.previousStep &&
                      _vm.$store.state.modules.dashboard.previousStep !==
                        'null' &&
                      _vm.$store.state.modules.dashboard.previousStep !==
                        'users-id-tracks' &&
                      _vm.$store.state.modules.dashboard.previousStep !==
                        'index-name'
                    ? _vm.$store.state.modules.dashboard.previousStep
                    : 'index',
                params: { id: _vm.itemId },
              }}},[_c('i',{staticClass:"uil-left-arrow-to-left"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$store.state.modules.dashboard.previousStep &&
                _vm.$store.state.modules.dashboard.previousStep !== "null" &&
                _vm.$store.state.modules.dashboard.previousStep !==
                  "users-id-tracks" &&
                _vm.$store.state.modules.dashboard.previousStep !== "index-name"
                  ? _vm.$t(
                      ("previous_step." + (_vm.$store.state.modules.dashboard.previousStep))
                    )
                  : _vm.$t("previous_step.index")))])]):_vm._e()],1),_vm._v(" "),_c('li'),_vm._v(" "),_c('li',[_vm._t("divider")],2),_vm._v(" "),_c('li',[_vm._t("items")],2),_vm._v(" "),_c('li',[_vm._t("divider")],2),_vm._v(" "),_c('li',[_vm._t("alerts")],2),_vm._v(" "),_c('li',[_vm._t("divider-alert")],2),_vm._v(" "),_c('div',[_vm._t("sort-by")],2),_vm._v(" "),_c('li',[_vm._t("visible-items")],2)]:(
            _vm.$route.name.includes('chats') && _vm.$vianovaConfig.slug !== 'oasis'
          )?[_c('li',{staticClass:"menu-title"},[_vm._v(_vm._s(_vm.$t("menuitems.menu.text")))]),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.logoRedirect}},[_c('i',{staticClass:"uil-left-arrow-to-left"}),_vm._v(_vm._s(_vm.$t("go_home")))])],1),_vm._v(" "),_c('div',{staticClass:"p-3 d-flex border-bottom"},[_c('b-input-group',{staticClass:"align-items-center",attrs:{"size":"sm"}},[_c('i',{staticClass:"rounded bg-light p-1 border-light uil uil-search search-icon",staticStyle:{"margin-right":"-2px"}}),_vm._v(" "),_c('b-form-input',{staticClass:"form-control bg-light border-light",attrs:{"rounded":"right","type":"search","placeholder":_vm.$t('chats.search_placeholder')},on:{"input":_vm.debounceSearchPatients},model:{value:(_vm.searchPatientInput),callback:function ($$v) {_vm.searchPatientInput=$$v},expression:"searchPatientInput"}})],1),_vm._v(" "),_c('b-button',{staticClass:"outline-primary ml-2",attrs:{"pill":"","variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show('addPatient')}}},[_c('b-icon',{attrs:{"scale":".85","icon":"pencil-square"}})],1)],1),_vm._v(" "),(
              (_vm.chatsLoading && !_vm.patientListCopy.length) || _vm.loadingNewPatients
            )?_c('Loading',{attrs:{"loading":(_vm.chatsLoading && !_vm.patientListCopy.length) || _vm.loadingNewPatients}}):_vm._e(),_vm._v(" "),(!_vm.chatsLoading && !_vm.patientListCopy.length)?_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"margin-top":"220px"}},[_c('h6',[_vm._v(_vm._s(_vm.$t("empty_states.chats_inbox")))])]):_vm._e(),_vm._v(" "),(!_vm.searchPatientInput)?_c('div',[(_vm.patientListCopy)?_c('ul',{staticClass:"list-unstyled chat-list group-list"},_vm._l((_vm.patientListCopy),function(patient){return _c('li',{key:patient.id,ref:"patient",refInFor:true,class:{ active: patient.id == _vm.$route.query.cid }},[(
                    patient &&
                    patient.conversation.participants.every(function (e) { return e.user; }) &&
                    patient.conversation.participants.length > 1
                  )?_c('nuxt-link',{key:patient.conversation_id,staticClass:"border-bottom",attrs:{"to":{
                    name: "chats-index-id-profile",
                    params: {
                      id: patient.conversation.participants.find(
                        function (participant) { return participant.messageable_type.includes('Patient'); }
                      )
                        ? patient.conversation.participants.find(
                            function (participant) { return participant.messageable_type.includes('Patient'); }
                          ).messageable_id
                        : null,
                    },
                    query: {
                      cid: patient.conversation_id,
                      t: Date.now(),
                      patient: patient.conversation.participants.find(
                        function (e) { return e.user.id != patient.messageable_id; }
                      ).user.display_name,
                    },
                  }}},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"user-img align-self-center mr-3",class:{
                        away: !patient.unread_count === 'away',
                        online: patient.unread_count === 'online',
                      }},[_c('div',{staticClass:"avatar-xs align-self-center"},[_c('span',{staticClass:"avatar-title rounded-circle bg-soft-primary text-primary"},[_vm._v(_vm._s(_vm.getPatientName(patient).charAt(0))+"\n                        ")])]),_vm._v(" "),_c('span',{staticClass:"user-status"})]),_vm._v(" "),_c('div',{staticClass:"media-body overflow-hidden"},[_c('h5',{staticClass:"text-truncate font-size-14 mb-1"},[_vm._v("\n                        "+_vm._s(_vm.getPatientName(patient))+"\n                      ")]),_vm._v(" "),_c('p',{staticClass:"text-truncate mb-0",style:({
                          fontWeight:
                            patient.unread_count > 0 ? 'bold' : 'normal',
                        })},[_vm._v("\n                        "+_vm._s(patient.conversation.last_message
                            ? JSON.parse(
                                patient.conversation.last_message.body
                              ).text
                            : _vm.$t("chats.no_messages"))+"\n                      ")])]),_vm._v(" "),_c('div',{staticClass:"font-size-11"},[_vm._v("\n                      "+_vm._s(_vm.momentTime(patient.conversation.updated_at))+"\n                    ")])])]):_vm._e()],1)}),0):_vm._e()]):_c('ul',{staticClass:"list-unstyled chat-list group-list"},_vm._l((_vm.allPatients),function(patient){return _c('li',{key:patient.id},[_c('nuxt-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.addNewPatient(patient)}}},[_c('div',{staticClass:"media align-items-center"},[_c('div',{staticClass:"user-img align-self-center mr-3"},[_c('div',{staticClass:"avatar-xs align-self-center"},[_c('span',{staticClass:"avatar-title rounded-circle bg-soft-primary text-primary"},[_vm._v(_vm._s(patient.display_name.charAt(0)))])]),_vm._v(" "),_c('span',{staticClass:"user-status"})]),_vm._v(" "),_c('div',{staticClass:"media-body overflow-hidden"},[_c('h5',{staticClass:"text-truncate font-size-14 mb-0"},[_vm._v("\n                      "+_vm._s(patient.display_name)+"\n                    ")])])])])],1)}),0)]:_vm._l((_vm.navItems),function(item){return [(item.isTitle)?_c('li',{key:item.id,staticClass:"menu-title"},[_vm._v("\n            "+_vm._s(_vm.$t(item.label))+"\n          ")]):_vm._e(),_vm._v(" "),(!item.isTitle && !item.isLayout)?_c('li',{key:item.id,class:{ divider: item.isDivider }},[(_vm.hasItems(item))?_c('a',{staticClass:"is-parent",class:{
                'has-arrow': !item.badge,
                'has-dropdown': item.badge,
              },attrs:{"href":"javascript:void(0);"}},[(item.icon)?_c('i',{class:("" + (item.icon))}):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t(item.label)))]),_vm._v(" "),(item.badge && item.badge.id == 'chat-badge-counter')?_c('span',{class:("badge badge-pill badge-" + (item.badge.variant) + " float-right")},[_vm._v(_vm._s(_vm.unreadCount))]):(item.badge)?_c('span',{class:("badge badge-pill badge-" + (item.badge.variant) + " float-right"),attrs:{"id":item.badge.id}},[_vm._v(_vm._s(_vm.$t(item.badge.text)))]):_vm._e()]):_vm._e(),_vm._v(" "),(!_vm.hasItems(item) && item.display && !item.isDivider)?_c('nuxt-link',{key:item.link,staticClass:"side-nav-link-ref",style:([
                _vm.$route.fullPath.includes('admin') ? _vm.adminMenuStyle : {} ]),attrs:{"to":(_vm.$vianovaConfig.slug === 'oasis' ||
                  _vm.$vianovaConfig.slug === 'am-diabetes' ||
                  _vm.$vianovaConfig.slug === 'sayf') &&
                item.id === 13
                  ? item.link
                  : _vm.itemId &&
                    item.link != '/' &&
                    _vm.$route.fullPath.includes('users')
                  ? {
                      name: ("users-id-" + (item.link)),
                      params: { id: _vm.itemId },
                    }
                  : _vm.previousRoute == 'index-name'
                  ? "/"
                  : _vm.previousRoute
                  ? ("/" + _vm.previousRoute)
                  : _vm.previousRouteComp() != 'null'
                  ? ("/" + (_vm.previousRouteComp()))
                  : item.link}},[(item.icon)?_c('i',{class:("" + (item.icon))}):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t(item.label) == "Dashboard" &&
                ((_vm.previousRoute && _vm.previousRoute != "patients") ||
                  (!_vm.previousRoute && _vm.previousRouteComp() != "patients"))
                  ? "Dashboard"
                  : _vm.$t(item.label) == "Dashboard" &&
                    (_vm.previousRoute == "patients" ||
                      _vm.previousRouteComp() == "patients")
                  ? "Patients"
                  : _vm.$t(item.label)))]),_vm._v(" "),(item.badge && item.badge.id == 'chat-badge-counter')?_c('span',{class:("badge badge-pill badge-" + (item.badge.variant) + " float-right")},[_vm._v(_vm._s(_vm.unreadCount))]):(item.badge)?_c('span',{class:("badge badge-pill badge-" + (item.badge.variant) + " float-right"),attrs:{"id":item.badge.id}},[_vm._v(_vm._s(_vm.$t(item.badge.text)))]):_vm._e()]):_vm._e(),_vm._v(" "),(item.isDivider)?_c('span'):_vm._e(),_vm._v(" "),(_vm.hasItems(item))?_c('ul',{staticClass:"sub-menu",attrs:{"aria-expanded":"false"}},_vm._l((item.subItems),function(subitem,index){return _c('li',{key:index},[(!_vm.hasItems(subitem))?_c('nuxt-link',{staticClass:"side-nav-link-ref",attrs:{"to":_vm.$route.fullPath.includes('users')
                      ? {
                          name: ("users-id-" + (subitem.link)),
                          params: { id: _vm.itemId },
                        }
                      : subitem.link}},[_vm._v(_vm._s(_vm.$t(subitem.label))+"\n                ")]):_vm._e(),_vm._v(" "),(_vm.hasItems(subitem))?_c('a',{staticClass:"side-nav-link-a-ref has-arrow",attrs:{"href":"javascript:void(0);"}},[_vm._v(_vm._s(_vm.$t(subitem.label))+"\n                ")]):_vm._e(),_vm._v(" "),(_vm.hasItems(subitem))?_c('ul',{staticClass:"sub-menu mm-collapse",attrs:{"aria-expanded":"false"}},_vm._l((subitem.subItems),function(subSubitem,index){return _c('li',{key:index},[_c('nuxt-link',{staticClass:"side-nav-link-ref",attrs:{"to":subSubitem.link}},[_vm._v(_vm._s(_vm.$t(subSubitem.label)))])],1)}),0):_vm._e()],1)}),0):_vm._e()],1):_vm._e()]})],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }